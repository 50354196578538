.confirm-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-dialog {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 1.5625rem;
  width: 28.125rem;
  max-width: 90%;
  text-align: center;
}

.confirm-icon {
  font-size: 3rem;
  margin-bottom: 0.9375rem;
}

.confirm-title {
  color: var(--error-color);
  margin-bottom: 0.9375rem;
  font-size: 1.25rem;
  letter-spacing: 0.125rem;
}

.confirm-message {
  color: var(--text-bright);
  margin-bottom: 1.5625rem;
  text-align: center;
  line-height: 1.5;
  font-size: 1rem;
}

.confirm-buttons {
  display: flex;
  justify-content: center;
  gap: 1.25rem;
}

.confirm-btn, .cancel-btn {
  font-family: var(--font-mono);
  padding: 0.625rem 1.25rem;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  transition: all 0.2s ease;
}

.confirm-btn {
  background-color: var(--error-color);
  color: white;
}

.confirm-btn:hover {
  background-color: #ff3355;
  transform: translateY(-0.125rem);
}

.cancel-btn {
  background-color: var(--accent-color);
  color: var(--text-bright);
}

.cancel-btn:hover {
  background-color: #666;
  transform: translateY(-0.125rem);
}