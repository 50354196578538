.mobile-warning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  font-family: var(--font-mono);
  padding: 1.25rem;
}

.mobile-warning-content {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius);
  padding: 1.875rem;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.5);
}

.warning-icon {
  font-size: 3rem;
  margin-bottom: 1.25rem;
}

.mobile-warning h2 {
  color: var(--error-color);
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
}

.mobile-warning p {
  color: var(--text-primary);
  margin-bottom: 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
}

.suggestion {
  color: var(--text-secondary);
  font-style: italic;
}