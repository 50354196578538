.info-boxes {
  display: flex;
  gap: 0.25%;
  height: 100%;
  min-height: 0; /* Important for Firefox flex child */
}

.info-box {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius);
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden; /* Prevent content from spilling out */
}

.time-info-box {
  width: auto;
  min-width: 27%; /* Much wider */
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-box-time {
  font-size: 1.3rem;
  text-align: center;
  color: var(--text-bright);
  font-weight: bold;
  margin-bottom: 3%;
  padding-bottom: 2%;
  border-bottom: 1px solid var(--border-color);
}

.info-box-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 3%;
  padding-bottom: 2%;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-bright);
  font-size: 1rem;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4%;
  width: 100%;
}

.info-item:last-child {
  margin-bottom: 0;
}

.info-item-label {
  font-size: 0.7rem;
  color: var(--text-secondary);
  text-transform: uppercase;
  margin-bottom: 1%;
  font-weight: bold;
  text-align: center;
}

.info-item-value {
  font-size: 0.9rem; /* Slightly larger */
  color: var(--text-bright);
  text-align: center;
}

.date-value, .uptime-value, .type-value {
  font-size: 1rem;
}

.network-info-box {
  width: auto;
  min-width: 36%; /* Slightly narrower */
  flex-grow: 0;
}

.status-container {
  display: flex;
  justify-content: space-around;  /* Changed to space-around instead of space-between */
  margin-bottom: 3%;
  gap: 0; /* Removed gap in favor of space-around */
  width: 100%;
}

.status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%; /* Narrower columns */
  padding: 0;
  max-width: 20%; /* Added max-width */
}

.status-label {
  color: var(--text-secondary);
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 2%;
}

.status-value {
  color: var(--text-bright);
  font-size: 0.9rem; /* Slightly larger */
  white-space: nowrap; /* Prevent line breaks */
}

.location-item {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.location-item .info-item-value {
  font-size: 0.9rem;
}

.ipv4-item {
  margin-top: auto;
}

.ipv4-item .info-item-value {
  font-size: 0.9rem;
}

.owner-item {
  margin-top: auto;
}

.owner-item .info-item-value {
  font-size: 1rem;
  font-weight: bold;
}

.online {
  color: var(--success-color);
}

.offline {
  color: var(--error-color);
}

.planet-gif {
  flex: 1;
  background-color: transparent; /* Removed background */
  border-radius: var(--border-radius);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 37%; /* Reduced width */
  max-width: 37%; /* Added max-width */
  min-height: 0;
}

.planet-gif img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin-left: -15%; /* Crops left side of the gif */
  margin-right: -15%; /* Crops right side of the gif */
  width: 130%; /* Makes the image wider to compensate for cropping */
}

/* Media queries for different screen sizes */
@media (max-width: 1600px) {
  .info-box-time {
    font-size: 1.2rem;
  }
  
  .date-value, .uptime-value, .type-value {
    font-size: 0.95rem;
  }
  
  .status-value {
    font-size: 0.85rem;
  }
}

@media (max-width: 1366px) {
  .info-box-time {
    font-size: 1.1rem;
  }
  
  .info-box-title {
    font-size: 0.9rem;
  }
  
  .date-value, .uptime-value, .type-value {
    font-size: 0.9rem;
  }
  
  .status-value, .location-item .info-item-value, .ipv4-item .info-item-value {
    font-size: 0.8rem;
  }
  
  .info-item-label, .status-label {
    font-size: 0.65rem;
  }
}

@media (max-height: 768px) {
  .info-boxes {
    gap: 0.25%;
  }
  
  .info-box {
    padding: 1.5%;
  }
}

/* Low zoom levels (25-50%) */
@media (max-resolution: 72dpi) {
  .info-box-time {
    font-size: 1.5rem;
  }
  
  .date-value, .uptime-value, .type-value {
    font-size: 1.3rem;
  }
  
  .status-value, .info-item-value {
    font-size: 1.2rem;
  }
  
  .info-item-label, .status-label {
    font-size: 0.9rem;
  }
}

/* Medium zoom (80%, 125%) */
@media (min-resolution: 77dpi) and (max-resolution: 110dpi) {
  .info-box-time {
    font-size: 1.3rem;
  }
  
  .date-value, .uptime-value, .type-value {
    font-size: 1.1rem;
  }
  
  .status-value, .info-item-value {
    font-size: 1rem;
  }
  
  .info-item-label, .status-label {
    font-size: 0.8rem;
  }
}

/* High resolution display support */
@media (min-width: 2560px) {
  .info-box-time {
    font-size: 1.2rem;
  }
  
  .info-box-title {
    font-size: 1rem;
  }
  
  .date-value, .uptime-value, .type-value {
    font-size: 1rem;
  }
  
  .status-value, .location-item .info-item-value, .ipv4-item .info-item-value {
    font-size: 0.9rem;
  }
  
  .info-item-label, .status-label {
    font-size: 0.8rem;
  }
}

/* Additional fixes for zoom levels */
@media (min-resolution: 120dpi) and (max-resolution: 160dpi) {
  .info-box-time {
    font-size: 1.1rem;
  }
  
  .info-box-title {
    font-size: 0.95rem;
  }
  
  .date-value, .uptime-value, .type-value {
    font-size: 1rem;
  }
  
  .status-value, .location-item .info-item-value, .ipv4-item .info-item-value {
    font-size: 0.9rem;
  }
  
  .info-item-label, .status-label {
    font-size: 0.75rem;
  }
  
  .info-item {
    margin-bottom: 2%;
  }
}

/* Fix for extremely high zoom levels (175%+) */
@media (min-resolution: 168dpi) {
  .info-box-time {
    font-size: 0.85rem;
    margin-bottom: 2%;
    padding-bottom: 1%;
  }
  
  .info-box-title {
    font-size: 0.75rem;
    margin-bottom: 2%;
    padding-bottom: 1%;
  }
  
  .date-value, .uptime-value, .type-value {
    font-size: 0.75rem;
  }
  
  .status-value, .location-item .info-item-value, .ipv4-item .info-item-value {
    font-size: 0.7rem;
  }
  
  .info-item-label, .status-label {
    font-size: 0.6rem;
  }
}