.content-box {
  background-color: var(--bg-content);
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 0; /* Important for Firefox flex child */
}

.run-tabs {
  display: flex;
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--border-color);
  overflow-x: auto;
  flex-shrink: 0;
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) var(--bg-secondary);
}

.run-tabs::-webkit-scrollbar {
  height: 0.25rem;
}

.run-tabs::-webkit-scrollbar-track {
  background: var(--bg-secondary);
}

.run-tabs::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border-radius: 0.1875rem;
}

.run-tab {
  padding: 0.3125rem 0.75rem;
  border-right: 1px solid var(--border-color);
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 0.95rem;
}

.run-tab.active {
  background-color: var(--accent-color);
  color: var(--text-bright);
}

.tab-title {
  margin-right: 0.3125rem;
}

.tab-close {
  font-size: 1rem;
  width: 1.125rem;
  height: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.tab-close:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.content-container {
  flex: 1;
  overflow: hidden;
  padding: 1%;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  min-height: 0; /* Important for Firefox flex child */
}

.content-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 0; /* Important for Firefox flex child */
}

.content-home {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-position: center;
  position: relative;
}

.content-home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.server-info, .no-server {
  position: relative;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1.25rem;
  border-radius: var(--border-radius);
  max-width: 80%;
}

.content-text-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 0; /* Important for Firefox flex child */
}

.content-text {
  white-space: pre-wrap;
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;
  font-size: 1.05rem;
  line-height: 1.5;
  word-wrap: break-word;
  word-break: break-word;
  width: 100%;
  box-sizing: border-box;
}

.content-text pre {
  width: 100%;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  margin: 0;
  padding: 0;
}

.content-image {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  box-sizing: border-box;
}

.image-container {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  height: auto;
  width: auto;
  display: block;
}

.content-pdf {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.content-pdf iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.timeline-viewer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  min-height: 0; /* Important for Firefox flex child */
}

.timeline {
  display: flex;
  justify-content: space-around;
  padding: 0.5rem 0;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius);
  margin-bottom: 0.375rem;
  flex-shrink: 0;
  overflow-x: auto;
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) var(--bg-secondary);
}

.timeline::-webkit-scrollbar {
  height: 0.25rem;
}

.timeline::-webkit-scrollbar-track {
  background: var(--bg-secondary);
}

.timeline::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border-radius: 0.1875rem;
}

.timeline-years-container {
  display: flex;
  overflow-x: auto;
  width: 100%;
  justify-content: flex-start;
  padding: 0 0.3125rem;
}

.timeline-year {
  padding: 0.3125rem 0.5rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  transition: background-color 0.2s ease;
  margin: 0 0.125rem;
  font-size: 0.95rem;
  flex-shrink: 0;
}

.timeline-year:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.timeline-year.active {
  background-color: var(--accent-color);
  color: var(--text-bright);
}

.timeline-details {
  flex: 1;
  overflow: auto;
  width: 100%;
  min-height: 0; /* Important for Firefox flex child */
}

.timeline-details h2 {
  display: none; /* Hide the year heading */
}

.timeline-months {
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.125rem;
}

.timeline-event {
  display: flex;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius);
  overflow: hidden;
  font-size: 0.95rem;
  width: 100%;
  box-sizing: border-box;
}

.timeline-event-date {
  padding: 0.375rem;
  min-width: 5.625rem;
  width: 5.625rem;
  flex-shrink: 0;
  background-color: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.9rem;
}

.timeline-event-content {
  padding: 0.375rem 0.5rem;
  flex: 1;
  overflow: hidden;
  min-width: 0;
  box-sizing: border-box;
  max-width: calc(100% - 5.625rem);
}

.timeline-description-wrapper {
  max-width: 100%;
  overflow: hidden;
}

.timeline-event-content h3 {
  margin-top: 0;
  margin-bottom: 0.1875rem;
  font-size: 1rem;
}

.timeline-event-content p {
  margin: 0;
  line-height: 1.4;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 100%;
}

.home-browser {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0; /* Important for Firefox flex child */
}

.directory-list-container {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  min-height: 0; /* Important for Firefox flex child */
}

.directory-list {
  list-style: none;
  padding: 0;
  width: 100%;
}

.directory-item {
  padding: 0.375rem 0.625rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  margin-bottom: 0.125rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 0.95rem;  /* Slightly reduced */
}

.directory-item:hover {
  background-color: var(--bg-secondary);
}

.directory-item-folder {
  color: var(--info-color);
}

.directory-item-file {
  color: var(--text-bright);
}

.directory-item-icon {
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.directory-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.directory-path {
  padding: 0.375rem 0.625rem;
  margin-bottom: 0.5rem;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius);
  font-family: var(--font-mono);
  overflow-x: auto;
  white-space: nowrap;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.95rem;  /* Slightly reduced */
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) var(--bg-secondary);
}

.directory-path::-webkit-scrollbar {
  height: 0.25rem;
}

.directory-path::-webkit-scrollbar-track {
  background: var(--bg-secondary);
}

.directory-path::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border-radius: 0.1875rem;
}

.directory-item-empty {
  padding: 0.375rem 0.625rem;
  color: var(--text-secondary);
  font-style: italic;
}

/* Scrollbar styling for all scrollable areas */
.content-text::-webkit-scrollbar,
.directory-list-container::-webkit-scrollbar,
.timeline-details::-webkit-scrollbar,
.content-image::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

.content-text::-webkit-scrollbar-track,
.directory-list-container::-webkit-scrollbar-track,
.timeline-details::-webkit-scrollbar-track,
.content-image::-webkit-scrollbar-track {
  background: var(--bg-content);
}

.content-text::-webkit-scrollbar-thumb,
.directory-list-container::-webkit-scrollbar-thumb,
.timeline-details::-webkit-scrollbar-thumb,
.content-image::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border-radius: 0.1875rem;
}

/* 2K+ */
@media (max-resolution: 72dpi) {
  .content-text {
    font-size: 1.3rem;
  }
  
  .directory-item, .directory-path {
    font-size: 1.3rem;
    padding: 0.5rem 0.75rem;
  }
  
  .timeline-event {
    font-size: 1.2rem;
  }
  
  .run-tab {
    font-size: 1.2rem;
    padding: 0.375rem 0.875rem;
  }
  
  .timeline-year {
    font-size: 1.2rem;
    padding: 0.375rem 0.625rem;
  }
  
  .content-image img {
    min-height: 80%;
    min-width: 80%;
  }
}

/* HD */
@media (max-width: 1366px) {
  .run-tab {
    font-size: 0.9rem;
    padding: 0.25rem 0.625rem;
  }
  
  .content-text {
    font-size: 0.95rem;
  }
  
  .directory-item, .directory-path {
    font-size: 0.9rem;
  }
  
  .timeline-event {
    font-size: 0.9rem;
  }
}

/* 2K */
@media (min-width: 2560px) {
  .content-text {
    font-size: 1rem;
  }
  
  .directory-item, .directory-path {
    font-size: 1rem;
  }
  
  .timeline-event {
    font-size: 1rem;
  }
  
  .run-tab {
    font-size: 1rem;
  }
  
  .timeline-year {
    font-size: 1rem;
  }
}

/* half-HD */
@media (min-resolution: 120dpi) and (max-resolution: 191dpi) {
  .directory-item, .directory-path {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
  }
  
  .directory-item-icon {
    margin-right: 0.3125rem;
  }
  
  .content-text {
    font-size: 0.8rem;
  }
  
  .timeline-event {
    font-size: 0.75rem;
  }
  
  .timeline-event-date {
    min-width: 4.375rem;
    width: 5.5rem;
  }
  
  .timeline-event-content {
    max-width: calc(100% - 4.375rem);
  }
}

/* way too low for us */
@media (min-resolution: 192dpi) {
  .directory-item, .directory-path {
    font-size: 0.65rem;
    padding: 0.1875rem 0.375rem;
  }
  
  .directory-item-icon {
    margin-right: 0.25rem;
  }
  
  .content-text {
    font-size: 0.7rem;
  }
  
  .timeline-event {
    font-size: 0.65rem;
  }
  
  .timeline-event-date {
    min-width: 6rem;
    width: 6rem;
    padding: 0.25rem;
  }
  
  .timeline-event-content {
    padding: 0.25rem 0.375rem;
    max-width: calc(100% - 3.75rem);
  }
  
  .run-tab {
    font-size: 0.7rem;
    padding: 0.1875rem 0.5rem;
  }
}