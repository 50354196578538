.main-interface {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.upper-section {
  flex: 7;
  display: flex;
  min-height: 0; /* Important for Firefox flex container */
}

.content-section {
  flex: 3;
  padding: 0.25%;
  min-width: 0; /* Prevent overflow in flex container */
  min-height: 0; /* Prevent overflow in flex container */
}

.sidebar-section {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 0.25%;
  margin-right: 0.25%;
  min-height: 0; /* Prevent overflow in flex container */
}

.sidebar-top {
  display: flex;
  gap: 0.25%;
  margin-bottom: 0.25%;
  height: 35%; /* Percentage of parent height instead of fixed */
  min-height: 0; /* Prevent overflow in flex container */
}

.sidebar-bottom {
  flex: 1;
  min-height: 0; /* Prevent overflow in flex container */
}

.lower-section {
  flex: 3;
  display: flex;
  padding: 0.25%;
  gap: 0.25%;
  min-height: 0; /* Prevent overflow in flex container */
}

.info-panels {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 0.25%;
  min-height: 0; /* Prevent overflow in flex container */
}

.keyboard-section {
  width: 55%;
  min-height: 0; /* Prevent overflow in flex container */
}

.inventory, .achievements {
  flex: 1;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0; /* Prevent overflow in flex container */
}

.achievements {
  margin-right: 0.25%;
}

.inventory-header, .achievements-header {
  background-color: var(--bg-terminal);
  color: var(--text-terminal);
  padding: 0.375rem 0.625rem;
  font-weight: bold;
  border-bottom: 1px solid var(--border-color);
  flex-shrink: 0; /* Prevent header from shrinking */
}

.inventory-items, .achievements-items {
  flex: 1;
  overflow-y: auto;
  padding: 0.25rem;
  min-height: 0; /* Important for Firefox flex child */
  scrollbar-width: thin;
  scrollbar-color: var(--bg-secondary) var(--bg-terminal);
}

.inventory-items::-webkit-scrollbar,
.achievements-items::-webkit-scrollbar {
  width: 0.375rem;
}

.inventory-items::-webkit-scrollbar-track,
.achievements-items::-webkit-scrollbar-track {
  background: var(--bg-terminal);
}

.inventory-items::-webkit-scrollbar-thumb,
.achievements-items::-webkit-scrollbar-thumb {
  background-color: var(--bg-secondary);
  border-radius: 0.1875rem;
}

.inventory-empty, .achievements-empty {
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: 10%;
}

.inventory-item {
  border-radius: var(--border-radius);
  margin-bottom: 0.25rem;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 2.375rem; /* Converted from 38px */
  min-height: 0;
  max-width: 100%;
}

.inventory-item:hover {
  transform: translateY(-0.125rem);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}

.inventory-item:active {
  transform: translateY(0);
}

.inventory-item-header {
  background-color: var(--accent-color);
  padding: 0.125rem 0.375rem;
  font-weight: bold;
  color: var(--text-bright);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.95rem;
  height: 100%; /* Make the header take the full item height */
  display: flex;
  align-items: center; /* Center text vertically */
  max-width: 100%;
}

.achievements-items.no-scroll {
  overflow-y: hidden;
}

.achievements-header-line {
  font-weight: bold;
  margin-bottom: 0.1875rem;
  color: var(--text-bright);
  text-align: center;
  padding-bottom: 0.1875rem;
  border-bottom: 1px solid var(--border-color);
  font-size: 0.9rem;
}

.achievements-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0px; /* No gap */
  height: calc(100% - 2.4rem); /* Adjust for header */
  width: 100%;
  padding: 0; /* No padding */
}

.achievement-row {
  display: flex;
  justify-content: space-between;
  gap: 0px; /* No gap */
}

.achievement-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent; /* Remove background */
  border-radius: var(--border-radius);
  cursor: help;
  transition: transform 0.2s ease;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* Reduced container size but kept icon visible */
  transform: scale(0.5);
}

.achievement-icon-container.locked {
  opacity: 0.5;
}

.achievement-icon-container.achieved {
  opacity: 1;
}

.achievement-icon {
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.achievement-tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid var(--accent-color);
  color: var(--text-bright);
  padding: 0.5rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  white-space: normal;
  pointer-events: none;
  z-index: 100;
  min-width: 6.875rem;
  width: auto;
  max-width: 10rem;
  text-align: center;
  box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.3);
  /* Adjust tooltip position for scaled container */
  transform: scale(1.5);
  transform-origin: center center;
}

.achievement-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -0.3125rem;
  border-width: 0.3125rem;
  border-style: solid;
  border-color: var(--accent-color) transparent transparent transparent;
}

.tooltip-title {
  font-weight: bold;
  margin-bottom: 0.1875rem;
  color: var(--info-color);
  font-size: 0.8rem;
}

.tooltip-description {
  font-size: 0.7rem;
  opacity: 0.9;
}

.achievements-locked {
  color: var(--error-color);
  font-weight: bold;
  text-align: center;
  font-size: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.achievements-unlocked-animation {
  color: var(--success-color);
  font-weight: bold;
  text-align: center;
  font-size: 1rem; /* Same size as locked message */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Match the locked style */
  animation: blink 0.5s infinite alternate;
}

.achievement-tooltip.tooltip-top {
  bottom: calc(100% - 2.5rem); /* Position above with enough space for scaled tooltip */
  z-index: 110;
}

.achievement-tooltip.tooltip-bottom {
  top: calc(100% - 6.5rem); /* Position below with enough space for scaled tooltip */
  z-index: 110;
}

/* Positioning classes - horizontal */
.achievement-tooltip.tooltip-center {
  left: 50%;
  transform: scale(1.5) translateX(-33%); /* Adjusted for scale */
}

.achievement-tooltip.tooltip-left {
  left: 0;
  transform: scale(1.5);
  transform-origin: left center;
}

.achievement-tooltip.tooltip-right {
  right: 0;
  transform: scale(1.5);
  transform-origin: right center;
}

/* Arrow for tooltip pointing up (bottom tooltip) */
.achievement-tooltip.tooltip-bottom::after {
  content: '';
  position: absolute;
  bottom: 100%;
  border-width: 0.3125rem;
  border-style: solid;
  border-color: transparent transparent var(--accent-color) transparent;
}

/* Arrow for tooltip pointing down (top tooltip) */
.achievement-tooltip.tooltip-top::after {
  content: '';
  position: absolute;
  top: 100%;
  border-width: 0.3125rem;
  border-style: solid;
  border-color: var(--accent-color) transparent transparent transparent;
}

/* Arrow positioning for center, left, right */
.achievement-tooltip.tooltip-center::after {
  left: 50%;
  margin-left: -0.3125rem;
}

.achievement-tooltip.tooltip-left::after {
  left: 1.25rem;
}

.achievement-tooltip.tooltip-right::after {
  right: 1.25rem;
}

@keyframes blink {
  from { opacity: 0.6; }
  to { opacity: 1; }
}

/* Media queries for different screen sizes */
@media (max-width: 1600px) {
  .sidebar-top {
    height: 30%;
  }
  
  .inventory-item {
    height: 2.25rem;
  }
}

@media (max-width: 1366px) {
  .sidebar-top {
    height: 25%;
  }
  
  .inventory-item-header {
    font-size: 0.9rem;
  }
  
  .inventory-item {
    height: 2.125rem;
  }
}

@media (max-height: 768px) {
  .sidebar-top {
    height: 38%;
  }
}

/* 80% zoom */
@media (max-resolution: 77dpi) {
  .inventory-item-header {
    font-size: 1.3rem;
  }
  
  .achievement-icon {
    font-size: 3.6rem;
  }
  
  .achievements-header-line,
  .achievements-locked,
  .achievements-unlocked-animation {
    font-size: 1.3rem;
  }
  
  .inventory-item {
    height: 3rem;
  }
}

/* 125% zoom */
@media (min-resolution: 120dpi) and (max-resolution: 144dpi) {
  .inventory-item-header {
    font-size: 0.9rem;
  }
  
  .inventory-item {
    height: 2.3rem;
  }
  .achievement-icon {
    font-size: 2.8rem;
  }
}

/* 150% zoom - This is our reference size that looked good */
@media (min-resolution: 144dpi) and (max-resolution: 168dpi) {
  .inventory-item-header {
    font-size: 0.85rem;
  }
  
  .inventory-item {
    height: 2.3rem;
  }
  .achievement-icon {
    font-size: 3rem;
  }
}

/* 175-200% zoom */
@media (min-resolution: 168dpi) and (max-resolution: 192dpi) {
  .inventory-item-header {
    font-size: 0.8rem;
  }
  
  .inventory-item {
    height: 1.95rem;
  }
  .achievement-icon {
    font-size: 2.5rem;
  }
}

/* Very high zoom (200%+) */
@media (min-resolution: 192dpi) {
  .inventory-item-header {
    font-size: 0.7rem;
    padding: 0.0625rem 0.3125rem;
  }
  
  .achievements-header-line {
    font-size: 0.7rem;
    margin-bottom: 0.125rem;
    padding-bottom: 0.125rem;
  }
  
  .achievements-locked,
  .achievements-unlocked-animation {
    font-size: 0.7rem;
  }
  
  .inventory-item {
    height: 1.7rem;
  }
  .achievement-icon {
    font-size: 2.2rem;
  }
}