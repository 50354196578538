.terminal {
  font-family: var(--font-mono);
  background-color: var(--bg-terminal);
  color: var(--text-terminal);
  height: 100%;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-right: 0.25%;
  min-height: 0; /* Prevent flex item from exceeding container */
  max-height: 100%; /* Ensure it doesn't grow beyond container */
}

.terminal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-secondary);
  padding: 0.375rem 0.625rem;
  flex-shrink: 0; /* Prevent header from shrinking */
}

.terminal-title {
  font-weight: bold;
  color: var(--text-bright);
}

.terminal-content {
  flex: 1;
  padding: 0.625rem;
  overflow-y: auto;
  white-space: pre-wrap;
  overflow-x: hidden;
  max-height: calc(100% - 2.1875rem); /* Set maximum height to prevent stretching */
  min-height: 0; /* Important for Firefox */
  scrollbar-color: var(--bg-secondary) var(--bg-terminal);
  scrollbar-width: thin;
}

.terminal-line {
  margin-bottom: 0.3125rem;
  font-size: 0.95rem;
  line-height: 1.4;
}

.terminal-input-line {
  display: flex;
  margin-top: 0.625rem;
  font-size: 0.95rem;
}

.terminal-prompt {
  margin-right: 0.3125rem;
  white-space: nowrap;
}

.terminal-user {
  color: var(--info-color);
  font-weight: bold;
}

.terminal-at {
  color: var(--text-terminal);
}

.terminal-server {
  color: var(--warning-color);
}

.terminal-colon {
  color: var(--text-terminal);
}

.terminal-path {
  color: var(--text-bright);
}

.terminal-dollar {
  color: var(--success-color);
}

.terminal-input {
  flex: 1;
  background-color: transparent;
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  min-width: 0; /* Allows input to shrink */
}

.directory {
  color: var(--info-color);
  font-weight: bold;
}

.file {
  color: var(--text-bright);
}

/* Add scrollbar styling */
.terminal-content::-webkit-scrollbar {
  width: 0.375rem;
}

.terminal-content::-webkit-scrollbar-track {
  background: var(--bg-terminal);
}

.terminal-content::-webkit-scrollbar-thumb {
  background-color: var(--bg-secondary);
  border-radius: 0.1875rem;
}

/* Low zoom levels (25-50%) */
@media (max-resolution: 72dpi) {
  .terminal-line, .terminal-input-line {
    font-size: 1.2rem;
  }
  
  .terminal-content {
    padding: 0.9375rem;
  }
  
  .terminal-header {
    padding: 0.5rem 0.75rem;
  }
}

/* Media queries for smaller screens */
@media (max-width: 1366px) {
  .terminal-line, .terminal-input-line {
    font-size: 0.85rem;
  }
}

/* High resolution display support */
@media (min-width: 2560px) {
  .terminal-line, .terminal-input-line {
    font-size: 1.1rem;
  }
  
  .terminal-content {
    padding: 0.9375rem;
  }
  
  .terminal-header {
    padding: 0.5rem 0.75rem;
  }
}

/* Additional fixes for zoom levels */
@media (min-resolution: 120dpi) and (max-resolution: 191dpi) {
  .terminal-line, .terminal-input-line {
    font-size: 0.85rem;
  }
  
  .terminal-content {
    max-height: calc(100% - 1.875rem); /* Adjust for zoomed view */
  }
}

/* Fix for extremely high zoom levels */
@media (min-resolution: 192dpi) {
  .terminal-line, .terminal-input-line {
    font-size: 0.75rem;
  }
  
  .terminal-content {
    padding: 0.5rem;
    max-height: calc(100% - 1.75rem);
  }
  
  .terminal-header {
    padding: 0.25rem 0.5rem;
  }
}