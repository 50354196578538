:root {
  --bg-primary: #000000;
  --text-primary: #c0c0c0;
  --text-bright: #e0e0e0;
  --bg-secondary: #202020;
  --text-secondary: #909090;
  --bg-terminal: #000000;
  --text-terminal: #c0c0c0;
  --bg-content: #131313;
  --accent-color: #505050;
  --error-color: #ff0033;
  --success-color: #00cc66;
  --info-color: #3498db;
  --warning-color: #f39c12;
  --bg-planet: #00212f;
  
  --font-mono: 'JetBrains Mono', 'Courier New', monospace;
  --font-sans: 'JetBrains Mono', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  
  --border-radius: 0.1875rem;
  --border-color: #333;
  
  font-size: 16px;
}

/* 8K */
@media (min-width: 7680px) {
  :root {
    font-size: 50px;
    --border-radius: 0.3rem;
  }
}

/* 5K, 6K for mac retinas */
@media (min-width: 5120px) and (max-width: 7679px) {
  :root {
    font-size: 37px;
    --border-radius: 0.275rem;
  }
}

/* 4K */
@media (min-width: 3840px) and (max-width: 5119px) {
  :root {
    font-size: 25px;
    --border-radius: 0.25rem;
  }
}

/* 2K */
@media (min-width: 2560px) and (max-width: 3839px) {
  :root {
    font-size: 17.5px;
    --border-radius: 0.225rem;
  }
}

/* FHD */
@media (min-width: 1920px) and (max-width: 2559px) {
  :root {
    font-size: 16.5px;
  }
}

/* HD+ */
@media (min-width: 1600px) and (max-width: 1919px) {
  :root {
    font-size: 15px;
    --border-radius: 0.175rem;
  }
}

/* HD */
@media (min-width: 1366px) and (max-width: 1599px) {
  :root {
    font-size: 14px;
    --border-radius: 0.15rem;
  }
}

/* half-HD */
@media (min-width: 1024px) and (max-width: 1365px) {
  :root {
    font-size: 11px;
    --border-radius: 0.125rem;
  }
}

/* no idea what this is */
@media (max-width: 1023px) {
  :root {
    font-size: 11.5px;
    --border-radius: 0.1rem;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: var(--font-sans);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  line-height: 1.5;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}