.keyboard {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  padding: 0.25%;
  gap: 0.25%;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.keyboard-row {
  display: flex;
  gap: 0.25%;
  justify-content: space-between;
  flex: 1;
}

.keyboard-key {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border-radius: var(--border-radius);
  min-width: 0;
  flex: 1;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.1s ease;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  padding: 0;
}

.keyboard-key.active {
  background-color: var(--accent-color);
  color: var(--text-bright);
}

.keyboard-key.held {
  background-color: var(--accent-color);
  color: var(--text-bright);
  opacity: 0.7;
}

.keyboard-key.key-1-25x {
  flex: 1.25;
}

.keyboard-key.key-1-5x {
  flex: 1.5;
}

.keyboard-key.key-2x {
  flex: 2;
}

.keyboard-key.key-2-25x {
  flex: 2.25;
}

.keyboard-key.key-6x {
  flex: 6;
}

.keyboard-spacebar {
  flex: 6;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border-radius: var(--border-radius);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.1s ease;
}

.keyboard-spacebar.active {
  background-color: var(--accent-color);
  color: var(--text-bright);
}

/* Low zoom levels (25-50%) */
@media (max-resolution: 72dpi) {
  .keyboard-key {
    font-size: 1.3rem;
  }
}

/* Media queries for smaller screens */
@media (max-width: 1600px) {
  .keyboard-key {
    font-size: 0.9rem;
  }
}

@media (max-width: 1366px) {
  .keyboard-key {
    font-size: 0.8rem;
  }
}

@media (max-height: 768px) {
  .keyboard {
    padding: 0.2%;
    gap: 0.2%;
  }
  
  .keyboard-row {
    gap: 0.2%;
  }
}

/* High resolution display support */
@media (min-width: 2560px) {
  .keyboard-key {
    font-size: 1.1rem;
  }
}

/* Additional fixes for zoom levels */
@media (min-resolution: 120dpi) and (max-resolution: 191dpi) {
  .keyboard-key {
    font-size: 0.7rem;
  }
}

/* Fix for extremely high zoom levels */
@media (min-resolution: 192dpi) {
  .keyboard-key {
    font-size: 0.6rem;
  }
  
  .keyboard {
    padding: 0.15%;
    gap: 0.15%;
  }
  
  .keyboard-row {
    gap: 0.15%;
  }
}